import { createRoot } from 'react-dom/client'
import { AuthProvider } from 'react-oidc-context'
import { Provider } from 'react-redux'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store/store'

import { App } from './App'
import { oidcConfig } from './keycloak'
import reportWebVitals from './reportWebVitals'

import './utils/FireBaseNotifications/firebaseMessaging'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './i18n'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const container = document.getElementById('root')!
const root = createRoot(container)

const cache = createCache({
  key: 'css',
  prepend: true,
})

root.render(
  <CacheProvider value={cache}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider
          {...oidcConfig}
          onSigninCallback={() => {
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            )
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App />
          </LocalizationProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </CacheProvider>
)

// If you want to start measuring performance, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
