import { components } from 'types/schemas'

export interface IDownloadingData {
  downloadProgress: number
  isDownloading: boolean
}

export interface IState {
  tabId: string
  DICOMs: {
    [accessionNumber: string]: {
      [studyId: number]: IDownloadingData
    }
  }
  ISO: {
    [accessionNumber: string]: {
      [studyId: number]: IDownloadingData
    }
  }
  ISOs: {
    [accessionNumber: string]: IDownloadingData
  }
  orderListCSVReport: {
    [downloadKey: string]: Pick<IDownloadingData, 'downloadProgress'>
  }
  orderPDFReport: IDownloadingData
  latestVersionOrderLegalCopyReport: {
    [accessionNumber: string]: Pick<IDownloadingData, 'downloadProgress'>
  }
  orderLegalCopyReport: {
    [id: number]: number
  }
  isUploading: { [fileId: string]: boolean }
  isDownloading: { [fileId: string]: boolean }
}

// archiving
export type TArchivingInitiateRequest = Omit<
  components['schemas']['DownloadStudyRq'],
  'eventId'
>

export enum STUDY_DOWNLOAD_TYPE_ENUM {
  DICOM = 'DICOM',
  ISO = 'ISO',
}
