import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/material'
import { PSLink } from 'components/ui-components/PSLink'

interface IViewCellProps {
  data: number | string
  externalCellProps?: {
    generateRoutePath?: (id: number | string) => string
    openInNewTab?: boolean
  }
}

export const ViewCell: FC<IViewCellProps> = ({
  data: id,
  externalCellProps,
}) => {
  const { t } = useTranslation()
  const { openInNewTab, generateRoutePath } = externalCellProps || {}

  return (
    <Box display='flex' justifyContent='flex-end'>
      <PSLink
        data-cy='viewCell'
        target={openInNewTab ? '_blank' : undefined}
        href={generateRoutePath?.(id) ?? ''}
      >
        {t('components.labels.view')} <ChevronRightIcon />
      </PSLink>
    </Box>
  )
}
