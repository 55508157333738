import { useTranslation } from 'react-i18next'

import { StyledContainer, StyledDescription } from './FallbackPages.styled'

export const UnavailablePage = () => {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <StyledDescription>{t('common.serviceUnavailable')}</StyledDescription>
    </StyledContainer>
  )
}
