import { unlockOrdersRequest } from 'store/assignProvidersTable/controllers'
import { useAppDispatch } from 'store/hooks'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

import { usePSSnackbar } from '../snackbar/usePSSnackbar'

export const useUnlockOrders = () => {
  const dispatch = useAppDispatch()
  const { showErrorSnackbarByErrorCode } = usePSSnackbar()

  const unlockOrders = async (accessionNumbers: string[]) => {
    return dispatch(
      unlockOrdersRequest({
        accessionNumbers: prepareArrayForSending(accessionNumbers) ?? '',
      })
    ).then((response) => {
      if (unlockOrdersRequest.rejected.match(response) && response.payload) {
        showErrorSnackbarByErrorCode(response.payload.errorCodes?.[0])

        return Promise.reject(response.payload.errorCodes?.[0])
      } else if (unlockOrdersRequest.fulfilled.match(response)) {
        return Promise.resolve()
      }
    })
  }

  return { unlockOrders }
}
