import { RootState } from 'store/store'

import {
  TMultiAssignmentRadiologist,
  TRadiologistsTableInfo,
  TSingleAssignmentRadiologist,
} from './types'

const getAssignmentRadiologistsData = (state: RootState) =>
  state.assignProvidersTable.radiologists

export const getSingleAssignmentRadiologistsList = (
  state: RootState
): TSingleAssignmentRadiologist[] =>
  getAssignmentRadiologistsData(state).singleAssignmentList ?? []

export const getMultiAssignmentRadiologistsList = (
  state: RootState
): TMultiAssignmentRadiologist[] =>
  getAssignmentRadiologistsData(state).multiAssignmentList ?? []

export const getRadiologistsTableInfo = (
  state: RootState
): TRadiologistsTableInfo => getAssignmentRadiologistsData(state).tableInfo

export const getIsSingleOrder = (state: RootState): boolean =>
  getRadiologistsTableInfo(state).isSingleOrder

export const getRadiologistsListLoadingState = (state: RootState) =>
  getAssignmentRadiologistsData(state).loadingState

export const getRadiologistsListQuery = (state: RootState) =>
  getAssignmentRadiologistsData(state).queryData

export const getAssignQuery = (state: RootState) =>
  state.assignProvidersTable.assignQuery

export const getAssignmentGroupData = (state: RootState) =>
  state.assignProvidersTable.groups

export const getRadiologistsGroupQuery = (state: RootState) =>
  getAssignmentGroupData(state).queryData

export const getGroupsListQuery = (state: RootState) =>
  getAssignmentGroupData(state).tableInfo

export const getAssignmentGroupsList = (state: RootState) =>
  getAssignmentGroupData(state).radiologists

export const getAssignmentGroupsLoadingState = (state: RootState) =>
  getAssignmentGroupData(state).loadingState

export const getHasAssignOrders = (state: RootState) => {
  const assignmentQuery = getAssignQuery(state)

  return Boolean(assignmentQuery.accessionNumbers.length)
}

export const getLockInformation = (state: RootState) =>
  state.assignProvidersTable.lockInformation

export const getOrdersFlags = (state: RootState) =>
  state.assignProvidersTable.flags

export const getOrdersPseudoFlags = (state: RootState) =>
  state.assignProvidersTable.pseudoFlags

export const getPatientInfo = (state: RootState, id: string) =>
  state.assignProvidersTable.lockInformation.patients[id]

export const getPreviouslyAssignedRadiologists = (state: RootState) =>
  state.assignProvidersTable.previouslyAssignedRadiologists
