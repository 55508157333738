import { IState } from 'store/filters/patients/types'

export const PATIENTS_FILTERS_SLICE_NAME = 'patientsFilters'

export const initialState: IState = {
  filtersData: {
    search: undefined,
    proscanMrn: undefined,
    sourceMrn: undefined,
    dateOfBirth: null,
    companyIds: [],
    genders: [],
    dateOfServiceFrom: null,
    dateOfServiceTo: null,
    showDuplicates: false,
  },
  queryData: {
    sort: undefined,
    page: 0,
    size: 100,
  },
}
