import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { bffApi } from 'services/bff'
import { dictionaryApi } from 'services/dictionary'
import { filesApi } from 'services/files'
import { orderReportVersionsApi } from 'services/orderReports'
import { ordersApi } from 'services/orders'
import { patientsApi } from 'services/patients'
import { providersApi } from 'services/providers'
import { savedFiltersApi } from 'services/savedFilters'
import { supportApi } from 'services/support'
import { migrate } from 'store/filters/migrations'
import { SetFiltersSliceTransform } from 'store/filters/transforms'

import reducer, { type TAppReducer } from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filters', 'userPreferences'],
  transforms: [SetFiltersSliceTransform],
  stateReconciler: autoMergeLevel2,
  migrate,
}

export const persistedReducer = persistReducer<TAppReducer>(
  persistConfig,
  reducer
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      bffApi.middleware,
      dictionaryApi.middleware,
      providersApi.middleware,
      filesApi.middleware,
      ordersApi.middleware,
      orderReportVersionsApi.middleware,
      patientsApi.middleware,
      savedFiltersApi.middleware,
      supportApi.middleware,
    ]),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
