import { createApi } from '@reduxjs/toolkit/query/react'
import { USER_ROLES } from 'constants/userRoles'
import _groupBy from 'lodash/groupBy'
import { axiosBaseQuery } from 'services/axiosBaseQuery'

import {
  TAttributeOption,
  TAttributePattern,
  TBillingStatus,
  TBillingType,
  TBodyPart,
  TDemographicValue,
  THoldReason,
  TModality,
  TOrderAuditTableFilterOptionsResponse,
  TOrderStatusRs,
  TPatientAuditTableFilterOptionsResponse,
  TPointsOfService,
  TProviderType,
  TQAReason,
  TSiteRecipientsRs,
  TSopClassRs,
  TSubspecialty,
  TTargetGroup,
  TTaxonomy,
  TThirdParty,
  TUrgency,
} from './types'

export const dictionaryApi = createApi({
  reducerPath: 'dictionaryApi',
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getOrderStatuses: builder.query<TOrderStatusRs[], void>({
      query: () => ({
        url: 'orders/statuses',
      }),
    }),
    getUrgencies: builder.query<TUrgency[], void>({
      query: () => ({
        url: 'orders/catalogs/urgencies',
      }),
    }),
    getUserRoles: builder.query<{ roles: USER_ROLES[] }, void>({
      query: () => ({
        url: 'users/roles',
      }),
    }),
    getUserSuffixes: builder.query<string[], void>({
      query: () => ({
        url: 'users/suffixes',
      }),
    }),
    getModalities: builder.query<TModality[], string | undefined>({
      query: (crossProcedureFilters) => ({
        url: `orders/catalogs/modalities${crossProcedureFilters ? `?${crossProcedureFilters}` : ''}`,
      }),
    }),
    getTaxonomies: builder.query<TTaxonomy[], void>({
      query: () => ({
        url: 'providers/catalogs/taxonomies',
      }),
    }),
    getDegrees: builder.query<TTaxonomy[], void>({
      query: () => ({
        url: 'providers/catalogs/degrees',
      }),
    }),
    getProviderTypes: builder.query<TProviderType[], void>({
      query: () => ({
        url: 'providers/types',
      }),
    }),
    getThirdParties: builder.query<TThirdParty[], void>({
      query: () => ({
        url: 'providers/catalogs/thirdParties',
      }),
    }),
    getUserSpecificUrgencies: builder.query<TUrgency[], void>({
      query: () => ({
        url: 'bff/orders/catalogs/urgencies',
      }),
    }),
    getUserSpecificBillingTypes: builder.query<TBillingType[], void>({
      query: () => ({
        url: 'bff/orders/catalogs/billingTypes',
      }),
    }),
    getBodyParts: builder.query<TBodyPart[], string | undefined>({
      query: (crossProcedureFilters) => ({
        url: `orders/catalogs/bodyParts${crossProcedureFilters ? `?${crossProcedureFilters}` : ''}`,
      }),
    }),
    getQaReasons: builder.query<TQAReason[], void>({
      query: () => ({
        url: 'orders/catalogs/qareasons',
      }),
    }),
    getSubspecialties: builder.query<TSubspecialty[], void>({
      query: () => ({
        url: 'providers/catalogs/subspecialties',
      }),
    }),
    getPointOfServices: builder.query<TPointsOfService[], void>({
      query: () => ({
        url: 'orders/catalogs/services',
      }),
    }),
    getDemographicReportTypes: builder.query<TDemographicValue[], void>({
      query: () => ({
        url: 'report/template/demographic/types',
      }),
      transformResponse: (rawResult: { types: TDemographicValue[] }) =>
        rawResult.types,
    }),
    getBillingStatuses: builder.query<TBillingStatus[], void>({
      query: () => ({
        url: 'orders/catalogs/billing-statuses',
      }),
    }),
    getHoldReasons: builder.query<THoldReason[], void>({
      query: () => ({
        url: 'orders/holdreasons',
      }),
    }),
    getTargetGroups: builder.query<
      TTargetGroup[],
      { includeSystemTargetGroups: boolean }
    >({
      query: ({ includeSystemTargetGroups }) => ({
        url: 'sr/target-groups',
        params: {
          includeSystemTargetGroups,
        },
      }),
    }),
    getOrderAuditFilterOptions: builder.query<
      TOrderAuditTableFilterOptionsResponse,
      void
    >({
      query: () => ({
        url: 'audit/grid',
      }),
    }),
    getPatientAuditFilterOptions: builder.query<
      TPatientAuditTableFilterOptionsResponse,
      void
    >({
      query: () => ({
        url: 'audit/patient/grid',
      }),
    }),
    getSopClasses: builder.query<TSopClassRs[], void>({
      query: () => ({
        url: 'orders/catalogs/sopclasses',
      }),
    }),
    getDeliveryPatternOptions: builder.query<TAttributeOption[], void>({
      query: () => ({
        url: 'org/attribute-patterns',
      }),
      transformResponse: (rowResult: TAttributePattern[]) => {
        const groupedOptions: Record<string, TAttributePattern[]> = _groupBy(
          rowResult,
          'group'
        )

        return Object.keys(groupedOptions).reduce<TAttributeOption[]>(
          (acc, key) => {
            acc.push({
              label: key,
              value: key,
              disabled: true,
              isHeader: true,
              group: key,
            })
            acc.push(...groupedOptions[key])

            return acc
          },
          []
        )
      },
    }),
    getRecipientBySite: builder.query<TSiteRecipientsRs, number>({
      query: (siteId) => ({
        url: `org/sites/${siteId}/recipient`,
      }),
    }),
  }),
})

export const {
  useGetOrderStatusesQuery,
  useGetUrgenciesQuery,
  useGetUserRolesQuery,
  useGetUserSuffixesQuery,
  useGetModalitiesQuery,
  useGetTaxonomiesQuery,
  useGetDegreesQuery,
  useGetProviderTypesQuery,
  useGetThirdPartiesQuery,
  useGetUserSpecificUrgenciesQuery,
  useGetUserSpecificBillingTypesQuery,
  useGetBodyPartsQuery,
  useGetQaReasonsQuery,
  useGetSubspecialtiesQuery,
  useGetPointOfServicesQuery,
  useGetDemographicReportTypesQuery,
  useGetBillingStatusesQuery,
  useGetHoldReasonsQuery,
  useGetTargetGroupsQuery,
  useGetDeliveryPatternOptionsQuery,
  useGetOrderAuditFilterOptionsQuery,
  useGetPatientAuditFilterOptionsQuery,
  useGetSopClassesQuery,
  useGetRecipientBySiteQuery,
} = dictionaryApi
