import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { AxiosProgressEvent } from 'axios'
import { PROGRESS_COMPLETE_VALUE, S3_FILE_GROUPS } from 'constants/files/files'
import { ORDER_ISO_NAME_REGEXP } from 'constants/regex'
import { getTabId } from 'store/filesDownloading/selectors'
import { setISODownloadProgress } from 'store/filesDownloading/slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { files } from 'utils/api/files/Files'
import { TGetFileLinkResponse } from 'utils/api/files/types'
import { downloadBlobFile } from 'utils/helpers/files/downloadBlobFile'
import { getDownloadProgress } from 'utils/helpers/files/getDownloadProgress'
import { useGetLinkForDownload } from 'utils/hooks/files/useGetLinkForDownload'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from '../types'

export const useHandleISOGenerateMessage = () => {
  const { getLinkForDownloadRequest } = useGetLinkForDownload()
  const { openErrorSnackbar } = usePSSnackbar()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const lastProgress = useRef<number | null>(null)
  const tabId = useAppSelector(getTabId, shallowEqual)

  const handleISODownloadProgress =
    (accessionNumber: string, studyId: number | null) =>
    (download: AxiosProgressEvent) => {
      const downloadProgress = getDownloadProgress(download)
      if (lastProgress.current === downloadProgress) {
        return
      }

      lastProgress.current = downloadProgress

      dispatch(
        setISODownloadProgress({
          progress: downloadProgress,
          accessionNumber,
          studyId,
        })
      )
    }

  const handleISOGenerateMessage: TMessageHandler = useCallback((event) => {
    try {
      const notificationBody: TFirebaseMessage = JSON.parse(
        event.data.notification.body
      )

      if (
        notificationBody.type ===
        FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_STUDY_ISO
      ) {
        if (tabId !== notificationBody.metaInfo.tabId) return

        getLinkForDownloadRequest(
          notificationBody.metaInfo.objKey,
          S3_FILE_GROUPS.DICOM
        )
          .then(async (archiveLink: TGetFileLinkResponse | null) => {
            if (archiveLink) {
              const fileName =
                new RegExp(ORDER_ISO_NAME_REGEXP).exec(archiveLink.url)?.[0] ??
                'RIS_Order.iso'
              const isoFile = await files.downloadBlobFile(archiveLink.url, {
                onDownloadProgress: handleISODownloadProgress(
                  notificationBody.metaInfo.accessionNumber,
                  notificationBody.metaInfo.studyId
                ),
              })

              return {
                isoFile,
                fileName,
              }
            }

            return Promise.reject(
              new Error('ISO downloading archiveLink is not provided')
            )
          })
          .then(async ({ isoFile, fileName }) => {
            if (isoFile.data) {
              downloadBlobFile(isoFile.data, fileName)
            } else {
              return Promise.reject(
                new Error('ISO downloading data is not provided')
              )
            }
          })
          .catch(() => {
            dispatch(
              setISODownloadProgress({
                accessionNumber: notificationBody.metaInfo.accessionNumber,
                progress: PROGRESS_COMPLETE_VALUE,
                studyId: notificationBody.metaInfo.studyId,
              })
            )
            openErrorSnackbar(
              t('common.notifications.generalErrorNotificationMessage')
            )
          })
      }

      if (
        notificationBody.type ===
        FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_STUDY_ISO
      ) {
        if (tabId !== notificationBody.metaInfo.tabId) return

        dispatch(
          setISODownloadProgress({
            accessionNumber: notificationBody.metaInfo.accessionNumber,
            progress: PROGRESS_COMPLETE_VALUE,
            studyId: notificationBody.metaInfo.studyId,
          })
        )
        openErrorSnackbar(notificationBody.message)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('JSON Parse error: ', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { handleISOGenerateMessage }
}
