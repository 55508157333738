import { DARK_FONT_COLOR, LIGHT_FONT_COLOR } from 'constants/colors'

export const pickTextColorBasedOnBgColor = (bgColor: string) => {
  const color = bgColor.startsWith('#') ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? DARK_FONT_COLOR
    : LIGHT_FONT_COLOR
}
