export enum ERROR_CODE {
  NETWORK_ERROR = '0',
  SYSTEM_ERROR = '00.00.00',
  USER_NOT_FOUND = '01.00.02',
  USER_PACS_UNIQUE = '01.00.07',
  PASSWORD_INCORRECT = '01.01.01',
  EMAIL_IS_REGISTERED = '01.01.03',

  COMPANY_NAME_EXISTS = '02.00.02',
  SITE_NAME_EXISTS = '02.00.04',
  PRACTICE_NAME_EXITS = '02.00.07',
  ORG_CODE_EXISTS = '02.00.12',
  INVALID_DELIVERY_REPORT_PATTERN = '02.00.15',

  INTERNAL_SERVER_ERROR = '03.00.01',
  NO_SUCH_ORDER_FOUND_BY_ID = '03.00.02',
  NO_SUCH_PROCEDURE_FOUND_BY_ID = '03.00.09',
  PROCEDURE_ID_EXISTS = '03.00.12',
  NO_SUCH_FLAG_FOUND_BY_ID = '03.00.16',
  NON_COMPLETED_FLAGS = '03.00.30',
  NO_ACTIVE_TASK = '03.01.00',
  NO_SHARED_STUDIES_FOR_ORDER = '03.00.63',
  DICOM_TSF_PENDING = '03.00.80',
  FILTER_ALREADY_EXIST = '03.00.81',

  NO_SUCH_ASSOCIATED_SITE_IDS_FOR_SITE_ADMIN = '06.00.03',
  NO_SUCH_COMPANY_FOUND_BY_ID = '06.02.00',

  INVALID_PROVIDER_ID = '04.00.10',
  NO_PERMISSION_FOR_PROVIDER_ID = '04.00.18',

  MRN_ALREADY_EXISTS_IN_THE_COMPANY = '05.00.05',
  PATIENT_NOT_FOUND = '05.00.08',
  PATIENT_DOESNT_HAVE_GOLDEN_RECORD = '05.00.09',

  NOT_AVAILABLE_ACTION_FOR_ROLE = '06.00.02',
  NUMBER_OF_ORDERS_EXCEEDS_LIMIT = '06.00.09',
  NO_PERMISSION_FOR_PATIENT_LIST = '06.00.15',

  NO_SUCH_REPORT = '07.00.13',

  NO_SUCH_DICOM_DESTINATION = '10.00.03',
  IMPOSSIBLE_TO_REMOVE_ACTIVE_DESTINATION = '10.00.04',
  IMPOSSIBLE_TO_REMOVE_LINKED_DESTINATION = '10.00.05',
  SERVER_GROUP_ALREADY_EXISTS = '10.00.07',
  NO_SUCH_DICOM_DESTINATION_GROUP = '10.00.09',
  DICOM_DESTINATION_ALREADY_EXISTS = '10.00.12',

  // INTERNAL FE
  ERR_CANCELED = 'ERR_CANCELED',
  BROWSER_API_NOTIFICATION_DISABLED = 'BROWSER_API_NOTIFICATION_DISABLED',
}
