import { ORDERS_TABLE_TAB } from 'store/ordersList/types'

export const migrate = (state: any) => {
  if (state) {
    const { filters, ...restState } = state
    const newState = {
      ...restState,
      filters: {
        ...filters,
        ordersFilters: {
          ...filters.ordersFilters,
          [ORDERS_TABLE_TAB.ALL]: {
            ...filters.ordersFilters[ORDERS_TABLE_TAB.ALL],
            filtersData: {
              ...filters.ordersFilters[ORDERS_TABLE_TAB.ALL].filtersData,
              isInactiveStatuses:
                filters.ordersFilters[ORDERS_TABLE_TAB.ALL].filtersData
                  .isInactiveStatuses ??
                filters.ordersFilters[ORDERS_TABLE_TAB.ALL].filtersData
                  .isFinalStatuses,
            },
          },
        },
      },
    }
    if (
      newState.filters.ordersFilters[ORDERS_TABLE_TAB.ALL].filtersData
        .isFinalStatuses
    ) {
      delete newState.filters.ordersFilters[ORDERS_TABLE_TAB.ALL].filtersData
        .isFinalStatuses
    }
    return Promise.resolve(newState)
  }
  return Promise.resolve(state)
}
