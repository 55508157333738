import { v4 as uuidv4 } from 'uuid'
import { IState } from 'store/filesDownloading/types'

export const FILES_DOWNLOADING_SLICE_NAME = 'filesDownloading'

export const INITIAL_STATE: IState = {
  tabId: uuidv4(),
  DICOMs: {},
  ISOs: {},
  ISO: {},
  orderListCSVReport: {},
  orderPDFReport: {
    downloadProgress: 0,
    isDownloading: false,
  },
  latestVersionOrderLegalCopyReport: {},
  orderLegalCopyReport: {},
  isUploading: {},
  isDownloading: {},
}
