export const COLOR_PICKER_COLORS = [
  '#EBEBEB',
  '#F44336',
  '#F50057',
  '#9C27B0',
  '#512DA8',
  '#536DFE',
  '#2196F3',
  '#0288D1',
  '#26C6DA',
  '#009688',
  '#2E7D32',
  '#80CBC4',
  '#78909C',
  '#FF8A80',
  '#FF80AB',
  '#EA80FC',
  '#B388FF',
  '#9FA8DA',
  '#BBDEFB',
  '#80D8FF',
  '#84FFFF',
  '#A7FFEB',
  '#4CAF50',
  '#00E676',
  '#B2FF59',
  '#FDD835',
  '#FFB300',
  '#FF9800',
  '#EF6C00',
  '#8D6E63',
]

export const LIGHT_FONT_COLOR = '#FFFFFF'
export const DARK_FONT_COLOR = '#000000'
