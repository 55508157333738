import { TBffLinkedOrderItem } from 'store/assignProvidersTable/types'
import { ORDER_STATUS_ENUM } from 'store/order/types'

export const getAvailableOrdersForAssignment = (
  orders: TBffLinkedOrderItem[],
  reassignmentNumbers?: Array<string>
) => {
  const availableOrders = orders.filter((order) => {
    if (reassignmentNumbers) {
      return reassignmentNumbers.includes(order.accessionNumber)
    }
    return (
      !order.isLocked &&
      order.orderStatus === ORDER_STATUS_ENUM.WAITING_FOR_ASSIGNMENT
    )
  })
  return availableOrders.sort(
    (a, b) => Number(a.accessionNumber) - Number(b.accessionNumber)
  )
}

export const getBlockedOrdersForAssignment = (
  orders: TBffLinkedOrderItem[],
  reassignmentNumbers?: Array<string>
) => {
  return orders
    .filter((order) => {
      if (reassignmentNumbers) {
        return !reassignmentNumbers.includes(order.accessionNumber)
      }
      return (
        order.isLocked ||
        order.orderStatus !== ORDER_STATUS_ENUM.WAITING_FOR_ASSIGNMENT
      )
    })
    .sort((a, b) => Number(a.accessionNumber) - Number(b.accessionNumber))
}
