import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Tooltip } from '@mui/material'
import { getPatientOptionLabel } from 'pages/orders/OrdersList/helpers/getPatientOptionLabel'
import { hasCamelCaseProperties } from 'utils/helpers/strings/hasCamelCaseProperties'

interface IPatientCellProps {
  data:
    | { lastName?: string; firstName?: string; active?: boolean }
    | { lastname?: string; firstname?: string }
    | null
}

export const PatientCell: FC<IPatientCellProps> = ({ data }) => {
  const { t } = useTranslation()

  const areKeysCamelCase = hasCamelCaseProperties(data)
  const firstname = areKeysCamelCase ? data?.firstName : data?.firstname
  const lastname = areKeysCamelCase ? data.lastName : data?.lastname

  return (
    <Box display='flex' alignItems='center' gap={0.5}>
      {firstname || lastname
        ? getPatientOptionLabel(firstname, lastname)
        : t('components.table.notApplicable')}
      {data && 'active' in data && !data.active ? (
        <Tooltip
          placement='top'
          title={t('domains.Patients.inactivePatientDueToMerge')}
          arrow
        >
          <InfoIcon fontSize='small' color='disabled' />
        </Tooltip>
      ) : null}
    </Box>
  )
}
