import { createTransform } from 'redux-persist'
import {
  DEFAULT_ALL_ORDERS_TABLE_DATE_OF_SERVICE_FILTER,
  DEFAULT_BILLING_ORDERS_TABLE_BILLING_STATUS_FILTER,
} from 'store/filters/orders/constants'
import { filtersReducer } from 'store/filters/slice'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { getValuable } from 'utils/helpers/general'

export type TFiltersSliceState = ReturnType<typeof filtersReducer>

export const SetFiltersSliceTransform = createTransform<
  TFiltersSliceState,
  TFiltersSliceState
>(
  (state) => getValuable(state) as TFiltersSliceState,
  ({ ordersFilters, ...rest }) => {
    const allOrdersTableData = ordersFilters?.[ORDERS_TABLE_TAB.ALL]
    const billingOrdersTableData = ordersFilters?.[ORDERS_TABLE_TAB.BILLING]

    const isBillingFilterProvided = getValuable(
      billingOrdersTableData.filtersData
    )

    const allFilterProvided = getValuable(allOrdersTableData.filtersData)
    const shouldApplyDefaultAllOrdersFilter =
      !allFilterProvided ||
      (Object.keys(allFilterProvided).length === 1 &&
        allFilterProvided.isInactiveStatuses === false)

    return {
      ordersFilters: {
        ...ordersFilters,
        [ORDERS_TABLE_TAB.ALL]: {
          ...allOrdersTableData,
          filtersData: {
            ...allOrdersTableData?.filtersData,
            dateOfService: shouldApplyDefaultAllOrdersFilter
              ? DEFAULT_ALL_ORDERS_TABLE_DATE_OF_SERVICE_FILTER
              : allFilterProvided?.dateOfService || {
                  period: null,
                  from: null,
                  to: null,
                },
            isInactiveStatuses:
              allOrdersTableData.filtersData?.isInactiveStatuses || false,
          },
        },
        [ORDERS_TABLE_TAB.BILLING]: {
          ...billingOrdersTableData,
          filtersData: {
            ...billingOrdersTableData.filtersData,
            billingStatuses: isBillingFilterProvided
              ? billingOrdersTableData.filtersData?.billingStatuses || []
              : DEFAULT_BILLING_ORDERS_TABLE_BILLING_STATUS_FILTER,
          },
        },
      },
      ...rest,
    }
  },
  { whitelist: ['filters'] }
)
