import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REPORT_MODALS } from 'store/orderReportInformation/types'
import { LOADING_STATE } from 'types/general'

import { initialState } from './constants'
import {
  createManualReportVersion,
  fetchIsFinalLegalCopyReleased,
  fetchOrderLegalCopies,
  fetchOrderReportCompareInformation,
  fetchOrderReportInformation,
} from './controllers'

export const name = 'orderReportInformation'

const orderReportInformationSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearReportCompareInformation: (state) => {
      state.compareInformation = initialState.compareInformation
    },
    completeOrderReportVersionCreating: ({ orderReportVersionModalData }) => {
      orderReportVersionModalData.isOrderReportVersionCreating = false
    },
    openReportModalsHandler: (
      state,
      { payload }: PayloadAction<REPORT_MODALS | null>
    ) => {
      state.openedReportModal = payload
    },
    clearOrderReportInformation: (state) => {
      state.reportInformation = initialState.reportInformation
      state.openedReportModal = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderReportInformation.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchOrderReportInformation.fulfilled, (state, action) => {
        state.reportInformation = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(fetchOrderReportInformation.rejected, (state) => {
        state.reportInformation = initialState.reportInformation
        state.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchOrderLegalCopies.pending, (state) => {
        state.legalCopies.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchOrderLegalCopies.fulfilled, (state, action) => {
        state.legalCopies.data = action.payload
        state.legalCopies.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(fetchOrderLegalCopies.rejected, (state) => {
        state.legalCopies.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchOrderReportCompareInformation.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(
        fetchOrderReportCompareInformation.fulfilled,
        (state, action) => {
          state.compareInformation = action.payload
          state.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(fetchOrderReportCompareInformation.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        fetchIsFinalLegalCopyReleased.pending,
        ({ orderReportVersionModalData }) => {
          orderReportVersionModalData.isFinalLegalCopyReleasedLoading = true
        }
      )
      .addCase(
        fetchIsFinalLegalCopyReleased.rejected,
        ({ orderReportVersionModalData }) => {
          orderReportVersionModalData.isFinalLegalCopyReleasedLoading = false
          orderReportVersionModalData.isFinalLegalCopyReleased =
            initialState.orderReportVersionModalData.isFinalLegalCopyReleased
        }
      )
      .addCase(
        fetchIsFinalLegalCopyReleased.fulfilled,
        ({ orderReportVersionModalData }, { payload }) => {
          orderReportVersionModalData.isFinalLegalCopyReleasedLoading = false
          orderReportVersionModalData.isFinalLegalCopyReleased =
            payload.isLegalCopyReleased
        }
      )
      .addCase(
        createManualReportVersion.pending,
        ({ orderReportVersionModalData }) => {
          orderReportVersionModalData.isOrderReportVersionCreating = true
        }
      )
      .addCase(
        createManualReportVersion.rejected,
        ({ orderReportVersionModalData }) => {
          orderReportVersionModalData.isOrderReportVersionCreating = false
        }
      )
  },
})

export const {
  openReportModalsHandler,
  completeOrderReportVersionCreating,
  clearOrderReportInformation,
  clearReportCompareInformation,
} = orderReportInformationSlice.actions

export default orderReportInformationSlice
