import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { StyledContainer, StyledDescription } from './FallbackPages.styled'

export const DeniedAccessPage = () => {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <Typography mb={2} variant='h5'>
        {t('components.titles.accessDeniedPageTitle')}
      </Typography>
      <StyledDescription>
        {t('common.accessDeniedPageDescription')}
      </StyledDescription>
    </StyledContainer>
  )
}
