import { DOWNLOAD_REPORT_TYPES } from 'store/orderReportInformation/types'

export const getReportFileName = ({
  accessionNumber,
  reportType,
  reportVersion,
  type = DOWNLOAD_REPORT_TYPES.PDF,
}: {
  accessionNumber: string | number
  reportType?: string
  reportVersion?: string | number
  type?: `${DOWNLOAD_REPORT_TYPES}`
}) => {
  const reportVersionPart = reportVersion ? ` ${reportVersion}` : ''
  return `Order-${accessionNumber}-Report_${reportType ?? ''}${reportVersionPart}.${type}`
}
