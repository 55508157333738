import { combineReducers } from '@reduxjs/toolkit'
import { bffApi } from 'services/bff'
import { dictionaryApi } from 'services/dictionary'
import { filesApi } from 'services/files'
import { orderReportVersionsApi } from 'services/orderReports'
import { ordersApi } from 'services/orders'
import { patientsApi } from 'services/patients'
import { providersApi } from 'services/providers'
import { savedFiltersApi } from 'services/savedFilters'
import { supportApi } from 'services/support'
import assignProvidersTableSlice from 'store/assignProvidersTable/slice'
import auditSlice from 'store/audit/slice'
import authSlice from 'store/auth/slice'
import companySlice from 'store/company/slice'
import dashboardSlice from 'store/dashboard/slice'
import destinationsSlice from 'store/destinations/slice'
import filesDownloadingSlice from 'store/filesDownloading/slice'
import { filtersReducer } from 'store/filters/slice'
import flagsSlice from 'store/flags/slice'
import orderSlice from 'store/order/slice'
import orderBillingInformationSlice from 'store/orderBillingInformation/slice'
import orderFlagsSlice from 'store/orderFlags/slice'
import orderReportInformationSlice from 'store/orderReportInformation/slice'
import ordersListSlice from 'store/ordersList/slice'
import patientSlice from 'store/patient/slice'
import practicesSlice from 'store/practice/slice'
import proceduresSlice from 'store/procedures/slice'
import providersSlice from 'store/providers/slice'
import sitesSlice from 'store/sites/slice'
import supportSlice from 'store/support/slice'
import tagsSlice from 'store/tags/slice'
import userPreferences from 'store/userPreferences/slice'
import usersSlice from 'store/users/slice'
import validationProgressSlice from 'store/validationProgress/slice'

import sharedStudiesSlice from './sharedStudies/slice'

const reducers = combineReducers({
  flags: flagsSlice.reducer,
  tags: tagsSlice.reducer,
  practices: practicesSlice.reducer,
  audit: auditSlice.reducer,
  providers: providersSlice.reducer,
  procedures: proceduresSlice.reducer,
  sites: sitesSlice.reducer,
  company: companySlice.reducer,
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  order: orderSlice.reducer,
  orderBillingInformation: orderBillingInformationSlice.reducer,
  users: usersSlice.reducer,
  orderReportInformation: orderReportInformationSlice.reducer,
  assignProvidersTable: assignProvidersTableSlice.reducer,
  orderFlags: orderFlagsSlice.reducer,
  ordersList: ordersListSlice.reducer,
  destinations: destinationsSlice.reducer,
  support: supportSlice.reducer,
  sharedStudies: sharedStudiesSlice.reducer,
  patient: patientSlice.reducer,
  [bffApi.reducerPath]: bffApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [providersApi.reducerPath]: providersApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [orderReportVersionsApi.reducerPath]: orderReportVersionsApi.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
  [savedFiltersApi.reducerPath]: savedFiltersApi.reducer,
  [supportApi.reducerPath]: supportApi.reducer,
  filesDownloading: filesDownloadingSlice.reducer,
  filters: filtersReducer,
  validationProgress: validationProgressSlice.reducer,
  userPreferences: userPreferences.reducer,
})

export default reducers

export type TAppReducer = ReturnType<typeof reducers>
