import { getProviderOptionLabel } from 'pages/orders/OrdersList/helpers/getProviderOptionLabel'
import { removeAssignedRadiologistFilterOption } from 'store/filters/orders/ordersSlice'
import { useAppDispatch } from 'store/hooks'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { TProviderDisplayName } from 'store/providers/types'

export const useGetAppliedAssignedRadiologistFilterPills = (
  filterData: Array<TProviderDisplayName>,
  tableName: Exclude<ORDERS_TABLE_TAB, ORDERS_TABLE_TAB.PREPARATION>
) => {
  const dispatch = useAppDispatch()

  return filterData.map((radiologist) => ({
    id: `${radiologist.providerId}-${radiologist.lastName}`,
    label: getProviderOptionLabel(radiologist),
    deleteFilterHandler: () =>
      dispatch(
        removeAssignedRadiologistFilterOption({
          id: radiologist.providerId as string,
          tableName,
        })
      ),
  }))
}
