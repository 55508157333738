import { PROCEDURE_STATUS } from 'store/procedures/types'

import { IState } from './types'

export const initialState: IState = {
  pageable: {
    size: 10,
    page: 0,
    sort: '',
  },
  filter: {
    modalities: [],
    bodyParts: [],
    procedures: [],
    cptCodes: [],
    status: PROCEDURE_STATUS.ACTIVE,
    subspecialties: [],
    sites: [],
    includeGeneral: false,
  },
}

export const PROCEDURES_FILTERS_SLICE_NAME = 'proceduresFilters'
