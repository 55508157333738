import { TProviderDisplayName } from 'store/providers/types'

export const transformProviderFieldForAutocomplete = ({
  firstName,
  lastName,
  middleName,
  degrees,
  providerId,
}: TProviderDisplayName) => ({
  firstName,
  lastName,
  middleName,
  degrees,
  providerId,
})
