import HowToRegIcon from '@mui/icons-material/HowToReg'
import { styled } from '@mui/material'

export const DeliveredToRefMDIconStyled = styled(HowToRegIcon)(
  ({ theme: { palette } }) => ({
    '&&': {
      color: `${palette.success.main} !important`,
    },
  })
)
