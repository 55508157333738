import { useTranslation } from 'react-i18next'
import { linkAndLockOrdersRequest } from 'store/assignProvidersTable/controllers'
import { useAppDispatch } from 'store/hooks'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

import { usePSSnackbar } from '../snackbar/usePSSnackbar'

export const useLockOrders = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { openErrorSnackbar } = usePSSnackbar()

  const lockOrders = async (
    accessionNumbers: string[],
    isReassignmentFlow: boolean = true
  ) => {
    return dispatch(
      linkAndLockOrdersRequest({
        accessionNumbers: prepareArrayForSending(accessionNumbers) ?? '',
        isReassignmentFlow,
      })
    ).then((response) => {
      if (linkAndLockOrdersRequest.rejected.match(response)) {
        response.payload &&
          openErrorSnackbar(
            t('common.notifications.rejectedNotificationMessage')
          )

        return Promise.reject(response.payload?.errorCodes?.[0])
      }

      return response
    })
  }

  return { lockOrders }
}
