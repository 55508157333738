import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'

import { FILTERS_TAG } from './constants'
import {
  TSavedFilterParams,
  TSavedUserPoolConfig,
  TUserPoolConfig,
  TUserPoolConfigPageRs,
} from './types'

export const savedFiltersApi = createApi({
  reducerPath: 'savedFiltersApi',
  tagTypes: [FILTERS_TAG],
  baseQuery: axiosBaseQuery({ baseUrl: '/orders' }),
  endpoints: (builder) => ({
    saveFilter: builder.mutation<{ id: number }, TUserPoolConfig>({
      query: (filter) => ({
        method: 'POST',
        url: 'pool-configs',
        body: filter,
      }),
      invalidatesTags: [{ type: FILTERS_TAG, id: 'LIST' }],
    }),
    updateFilter: builder.mutation<
      void,
      Partial<TUserPoolConfig> & Pick<TSavedUserPoolConfig, 'id'>
    >({
      query: (filter) => ({
        method: 'PATCH',
        url: `pool-configs/${filter.id}`,
        body: filter,
      }),
      invalidatesTags: [{ type: FILTERS_TAG, id: 'LIST' }],
    }),
    deleteFilter: builder.mutation<void, number>({
      query(id) {
        return {
          url: `pool-configs/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (_result, _error, id) => [
        { type: FILTERS_TAG, id },
        { type: FILTERS_TAG, id: 'LIST' },
      ],
    }),
    getFilter: builder.query<TSavedUserPoolConfig, number>({
      query: (id) => ({
        url: `pool-configs/${id}`,
      }),
      providesTags: (_result, _error, id) => [{ type: FILTERS_TAG, id }],
    }),
    filtersPaginated: builder.infiniteQuery<
      TUserPoolConfigPageRs,
      { tab: ORDERS_TABLE_TAB; search?: string },
      TSavedFilterParams
    >({
      infiniteQueryOptions: {
        initialPageParam: {
          page: 0,
          size: 20,
        },
        getNextPageParam: (lastPage, _allPages, lastPageParam) => {
          const nextPage = lastPageParam.page + 1
          const remainingPages = lastPage?.totalPages - nextPage

          if (remainingPages <= 0) {
            return undefined
          }

          return {
            ...lastPageParam,
            page: nextPage,
          }
        },
      },
      query: ({ pageParam: { page, size }, queryArg }) => {
        return {
          url: `pool-configs?page=${page}&size=${size}&tabName=${queryArg.tab}&search=${queryArg.search}`,
          method: 'GET',
        }
      },
      providesTags: (result) =>
        /* @ts-expect-error ignore odd typing issue */
        result?.content
          ? [
              /* @ts-expect-error ignore odd typing issue */
              ...result.content.map(({ id }) => ({ type: FILTERS_TAG, id })),
              { type: FILTERS_TAG, id: 'LIST' },
            ]
          : [{ type: FILTERS_TAG, id: 'LIST' }],
    }),
  }),
})

export const {
  useSaveFilterMutation,
  useUpdateFilterMutation,
  useDeleteFilterMutation,
  useFiltersPaginatedInfiniteQuery,
  useLazyGetFilterQuery,
} = savedFiltersApi
