import { IState } from 'store/sharedStudies/types'
import { LOADING_STATE } from 'types/general'

export const SHARED_STUDIES_SLICE_NAME = 'sharedStudies'

export const SHARED_STUDIES_INITIAL_STATE: IState = {
  list: {
    totalPages: 0,
    totalElements: 0,
    first: false,
    last: false,
    content: [],
    pageable: {
      page: 0,
      size: 10,
    },
    loadingState: LOADING_STATE.idle,
  },
  orderSharedStudies: {
    loadingState: LOADING_STATE.idle,
    studies: [],
    accessionNumber: undefined,
    proscanMrn: undefined,
    isDownloadReportAvailable: false,
    isDownloadIsoAvailable: false,
    isViewReady: false,
    siteId: undefined,
  },
}
