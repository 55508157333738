const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL
const FRONT_URL = import.meta.env.VITE_FRONT_URL
const TEXTGEARS_KEY = import.meta.env.VITE_TEXTGEARS_KEY

// Firebase notifications configs
const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY
const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN
const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID
const FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
const FIREBASE_MESSAGING_SENDER_ID = import.meta.env
  .VITE_FIREBASE_MESSAGING_SENDER_ID
const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID
const FIREBASE_VAPID = import.meta.env.VITE_FIREBASE_VAPID
const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT

const PROJECT_ID = import.meta.env.CYPRESS_PROJECT_ID

export const configs = {
  API_ENDPOINT,
  FRONT_URL,
  KEYCLOAK_URL,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_VAPID,
  PROJECT_ID,
  ENVIRONMENT,
  TEXTGEARS_KEY,
}

export const NPI_SEARCH_LINK = 'https://npiregistry.cms.hhs.gov/search'
