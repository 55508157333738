export enum USER_ROLES {
  SYS_ADMIN = 'SYS_ADMIN',
  PRACTICE_USER = 'PRACTICE_USER',
  SITE_USER = 'SITE_USER',
  SITE_USER_ADMIN = 'SITE_ADMIN',
  SUPER_USER = 'SUPER_USER',
  PROCTOR = 'PROCTOR',
  PROVIDER = 'PROVIDER',
  INDIVIDUAL = 'INDIVIDUAL',
  PROVIDER_ASSISTANT = 'PROVIDER_ASSISTANT',
}

export const ALL_ROLES = [
  USER_ROLES.PRACTICE_USER,
  USER_ROLES.SITE_USER_ADMIN,
  USER_ROLES.SITE_USER,
  USER_ROLES.SUPER_USER,
  USER_ROLES.SYS_ADMIN,
  USER_ROLES.PROCTOR,
  USER_ROLES.PROVIDER,
  USER_ROLES.INDIVIDUAL,
  USER_ROLES.PROVIDER_ASSISTANT,
]

export const SUPER_USER_ONLY_ROLES = [USER_ROLES.SUPER_USER]

export const SUPER_USER_ROLES = [USER_ROLES.SUPER_USER, USER_ROLES.SYS_ADMIN]

export const SITE_USER_ROLES = [
  USER_ROLES.SITE_USER,
  USER_ROLES.SITE_USER_ADMIN,
]

export const PRACTICE_USER_ROLES = [
  USER_ROLES.PRACTICE_USER,
  USER_ROLES.PROVIDER,
]

export const SITE_AND_SUPER_USER_ROLES = [
  ...SITE_USER_ROLES,
  ...SUPER_USER_ROLES,
]

export const SITE_ADMIN_AND_SUPER_USER_ROLES = [
  USER_ROLES.SITE_USER_ADMIN,
  ...SUPER_USER_ROLES,
]

export const GENERAL_ROLES = [
  ...SITE_AND_SUPER_USER_ROLES,
  USER_ROLES.PRACTICE_USER,
  USER_ROLES.PROVIDER,
  USER_ROLES.PROVIDER_ASSISTANT,
]

export const SHARED_STUDIES_ROLES = [
  USER_ROLES.PRACTICE_USER,
  USER_ROLES.SITE_USER_ADMIN,
  USER_ROLES.SITE_USER,
  USER_ROLES.SUPER_USER,
  USER_ROLES.SYS_ADMIN,
  USER_ROLES.PROCTOR,
  USER_ROLES.INDIVIDUAL,
]

export const PATIENTS_ROLES = [
  USER_ROLES.PRACTICE_USER,
  USER_ROLES.SITE_USER_ADMIN,
  USER_ROLES.SITE_USER,
  USER_ROLES.SUPER_USER,
  USER_ROLES.SYS_ADMIN,
  USER_ROLES.PROCTOR,
  USER_ROLES.PROVIDER,
]
