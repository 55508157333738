import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/hooks'
import { finishEditByEntityType } from 'store/order/controllers'
import { CAPTURE_TYPE_ENUM } from 'store/order/types'
import {
  completeOrderReportVersionCreating,
  openReportModalsHandler,
} from 'store/orderReportInformation/slice'
import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from 'utils/FireBaseNotifications/types'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

export const useHandleManualReportVersionMessage = () => {
  const { openSuccessSnackbar } = usePSSnackbar()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleManualReportVersionMessage: TMessageHandler = useCallback(
    (event) => {
      try {
        const notificationBody: TFirebaseMessage = JSON.parse(
          event.data.notification.body
        )

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.REPORT_CREATION
        ) {
          dispatch(
            finishEditByEntityType({
              captureTypes: [
                CAPTURE_TYPE_ENUM.REPORT_EDIT,
                CAPTURE_TYPE_ENUM.REPORT_VIEW,
              ],
              accessionNumber: notificationBody.metaInfo.orderAccessionNumber,
            })
          ).then(() => {
            openSuccessSnackbar(
              t('domains.RISOrders.createManualReportVersionSuccessful')
            )
            dispatch(openReportModalsHandler(null))
            dispatch(completeOrderReportVersionCreating())
          })
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('JSON Parse error: ', error)
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { handleManualReportVersionMessage }
}
